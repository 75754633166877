input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
}

input[type="date"],
input[type="time"] {
    position: relative;
}

.optionColor {
    color: #fff;
    background-color: #002857;
}

.globalsetting-form .intro-y {
    z-index: 1;
}

.thumb-hw-50 {
    height: 50px;
    width: 50px;
}
.object-fit-cover-center {
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}
.onFocusBorderNone input[role="combobox"]:focus {
    --tw-ring-shadow: none;
    --tw-ring-color: none;
}

.globalsetting-form .intro-y {
    z-index: 1;
}

.upload-thumb > img {
    background: #eff4f8;
    border: 2px solid #eff4f8;
}
.upload-thumb .remove {
    position: absolute;
    top: -5px;
    right: -5px;
    border: 2px solid #fff;
    color: #fff;
    background: #000;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    border-radius: 100%;
    z-index: 99;
}

.dropzone {
    padding: 0;
    display: block;
}
.dropzone p {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-cover div {
    z-index: 99;
}
.bg-cover {
    background: #ddd;
    height: 225px;
    border-radius: 10px;
}
.bg-cover-img {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
}
.bg-cover-img img {
    height: 225px;
    object-fit: cover;
    object-position: center;
    filter: blur(8px);
}
.bg-cover-color {
    background: #ddd;
    background: -webkit-linear-gradient(to right, rgba(0, 48, 100, 0.2), rgba(0, 101, 210, 0.5));
    background: linear-gradient(to right, rgba(0, 48, 100, 0.2), rgba(0, 101, 210, 0.5));
    height: 225px;
    border-radius: 10px;
}
.bg-cover-title-shadow {
    border-radius: 20px;
    background: #003064;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.3);
    color: #fff;
}

.react-datepicker__triangle {
    left: -20px !important;
}

.react-datepicker-popper {
    width: 280px;
}

.react-datepicker {
    border: none !important;
    background-color: #ffffff !important;
    box-shadow: 0px 3px 20px #00000014;
    width: 100%;
}

.react-datepicker__header {
    background-color: #ffffff !important;
    border-bottom: 1px solid #f0f0f0 !important;
    width: 100%;
    padding: 0.4rem !important;
}

.react-datepicker__month-container {
    width: 100%;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
    color: #216ba5 !important;
}

.react-datepicker__day-names {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.react-datepicker__week {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
}

.color-red {
    color: red;
}

.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block !important;
    line-height: 1.7rem !important;
    margin: 0.166rem !important;
    text-align: center !important;
    width: 1.7rem !important;
}

.job-pending-style label {
    margin-bottom: 0;
    margin-right: 10px;
}

.job-pending-style .form-select {
    max-width: 125px;
    min-width: 125px;
}

.job-pending-style .tom-select {
    min-width: 175px;
    max-width: 250px;
}

.align-items-baseline {
    align-items: baseline !important;
}

@media only screen and (max-width: 1500px) {
    .report-form .form-control,
    .job-filter-form .form-control,
    .job-filter-style {
        width: 200px;
    }

    .job-pending-style {
        width: 100%;
        flex-direction: column;
    }

    .job-pending-style label {
        width: 4rem;
    }
}

.select__input-container input:focus,
.select__input-container input:active {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    outline: none !important;
}

.table th {
    text-transform: uppercase;
}

textarea {
    min-height: 75px;
}

.globalsetting-form .intro-y {
    z-index: 1;
}

@media (min-width: 1024px) {
    .auth-logo {
        display: none !important;
    }
}

@media (max-width: 1024px) {
    .login-logo-section {
        margin: 20px auto auto !important;
        z-index: 1;
    }
}

@media (max-width: 1023.98px) {
    .content-vh-center {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
